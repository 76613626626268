import {createAsyncThunk, PayloadAction, SerializedError} from "@reduxjs/toolkit"
import {AlarmMinimalRef} from "@views/alarms/domain/alarms.model"
import api from "@common/services/api.service"
import {ApplicationResponse, TaggedDataState} from "@common/domain/common.model"
import AppConstants from "@src/environment/app.constants"
import {AndonStore} from "@views/andon/domain/andon.model"
import {TaggedAction} from "@common/domain/common.props"
import moment from "moment-timezone"
import {convertFormat} from "@src/utils/dates"
import {createSecuredSlice} from "@utils/auth.utils";

export const fetchAndonCurrentAlarm = createAsyncThunk<TaggedDataState<AlarmMinimalRef[]>, TaggedAction>("andon/fetchCurrentAlarm",

    async (payload) => {
        const {assemblyId} = payload.parameters
        const response = await api.get<ApplicationResponse<AlarmMinimalRef[]>>(`${AppConstants.api}/andon/active/all?assemblyId=${assemblyId}&startDate=${payload.parameters.startDate || ""}&endDate=${payload.parameters.endDate || ""}`)

        const data = response.data.data?.map((record) => {
            const processed = Object.assign({}, record)
            const utcTimestamp = moment.tz(record.issueRaiseTime.time, convertFormat(record.issueRaiseTime.format), record.issueRaiseTime.timezone)
            processed.issueRaiseTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a")
            if (record.issueResolveTime) {
                const utcTimestamp = moment.tz(record.issueResolveTime.time, convertFormat(record.issueResolveTime.format), record.issueResolveTime.timezone)
                processed.issueResolveTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a")
            } else processed.issueResolveTimestamp = '--'
            if (record.issueAckTime) {
                const utcTimestamp = moment.tz(record.issueAckTime.time, convertFormat(record.issueAckTime.format), record.issueAckTime.timezone)
                processed.issueAckTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a")
            } else processed.issueAckTimestamp = '--'
            if (record.issueCloseTime) {
                const utcTimestamp = moment.tz(record.issueCloseTime.time, convertFormat(record.issueCloseTime.format), record.issueCloseTime.timezone)
                processed.issueCloseTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a")
            } else processed.issueCloseTimestamp = '--'

            return processed
        }) || []

        return {
            tag: payload.tag,
            data
        }

    })

export const fetchAlarmHistory =
    createAsyncThunk<TaggedDataState<any>, TaggedAction>("andon/fetchAlarmHistory",
        async (payload) => {
            const {assemblyId} = payload.parameters
            const url = `${AppConstants.api}/andon/history?assemblyId=${assemblyId}&startDate=${payload.parameters.startDate || ""}&endDate=${payload.parameters.endDate || ""}`
            const response = await api.get<ApplicationResponse<AlarmMinimalRef[]>>(url)

            const data = response.data.data?.map((record) => {
                const processed = Object.assign({}, record)
                const utcTimestamp = moment.tz(record.issueRaiseTime.time, convertFormat(record.issueRaiseTime.format), record.issueRaiseTime.timezone)
                processed.issueRaiseTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a")
                if (record.issueResolveTime) {
                    const utcTimestamp = moment.tz(record.issueResolveTime.time, convertFormat(record.issueResolveTime.format), record.issueResolveTime.timezone)
                    processed.issueResolveTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a")
                } else processed.issueResolveTimestamp = '--'
                if (record.issueAckTime) {
                    const utcTimestamp = moment.tz(record.issueAckTime.time, convertFormat(record.issueAckTime.format), record.issueAckTime.timezone)
                    processed.issueAckTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a")
                } else processed.issueAckTimestamp = '--'
                if (record.issueCloseTime) {
                    const utcTimestamp = moment.tz(record.issueCloseTime.time, convertFormat(record.issueCloseTime.format), record.issueCloseTime.timezone)
                    processed.issueCloseTimestamp = utcTimestamp.clone().local().format("D MMM YYYY hh:mm a")
                } else processed.issueCloseTimestamp = '--'

                return processed
            }) || []

            return {
                tag: payload.tag,
                data
            }


        })


const andonSlice = createSecuredSlice({
    name: 'andon',
    initialState: {currentAlarms: {}, history: {}} as AndonStore,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAndonCurrentAlarm.pending, (state, action: { meta: { arg: TaggedAction } }) => {
                const {arg: {tag}} = action.meta
                state.currentAlarms[tag] = {
                    status: "loading",
                    error: null,
                    data: []
                }
            })
            .addCase(fetchAndonCurrentAlarm.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload

                state.currentAlarms[tag] = {
                    status: "idle",
                    error: null,
                    data
                }
            })
            .addCase(fetchAndonCurrentAlarm.rejected, (state,
                                                       action: PayloadAction<unknown, string,
                                                           { arg: TaggedAction }, SerializedError>) => {
                const {arg: {tag}} = action.meta
                state.currentAlarms[tag] = {
                    status: "failed",
                    error: action.error.message,
                    data: []
                }
            })
            .addCase(fetchAlarmHistory.pending, (state, action: { meta: { arg: TaggedAction } }) => {
                const {arg: {tag}} = action.meta
                state.history[tag] = {
                    status: "loading",
                    error: null,
                    data: []
                }
            })
            .addCase(fetchAlarmHistory.fulfilled, (state, action) => {
                const {
                    tag,
                    data
                } = action.payload

                state.history[tag] = {
                    status: "idle",
                    error: null,
                    data
                }
            })
    }
})

export default andonSlice.reducer