import {StandardTimestamp, StateTrackedData} from "@common/domain/common.model";
import {v4 as uuid4} from 'uuid';


export interface ChecksheetRevisionMinimalRef {
    name: string,
    checksheet_id: string,
    revision: string,
    revision_description: string,
    created_on: string,
}

export interface ChecksheetsMinimalRef {
    id: string
    title: string
    description: string
    department: {
        id: string
        name: string
    }
    checkedBy: {
        id: string
        name: string
    }[]
    createdBy?: string
}

export interface ChecksheetsEntryMinimalRef {
    id: string
    // checksheetId: string
    createdOn?: StandardTimestamp
    details: ChecksheetEntryDetailsRef[]
}

export interface ChecksheetEntryDetailsRef {
    id: string
    title: string
    // detail: string
    value: string
}


export interface ChecksheetsStore {
    minimal: {
        [tag: string]: StateTrackedData<ChecksheetsMinimalRef[]>
    },
    entries: {
        [tag: string]: StateTrackedData<ChecksheetsEntryMinimalRef[]>
    }
    info: StateTrackedData<ChecksheetEntryInfoRef | undefined>
    template: StateTrackedData<ChecksheetTemplateRef | undefined>
    templateList: StateTrackedData<ChecksheetsMinimalRef[] | []>
    revisionList: {
      [tag: string]: StateTrackedData<ChecksheetRevisionMinimalRef[] | []>
    }
}


// Enumeration for different input variants in a checksheet.
export type ChecksheetInputVariant = 'NONE' | 'DATE' | 'SELECTION' | 'TEXT';

// Enumeration for different cell types within a checksheet.
export type CellType = 'text' | 'image' | 'input';

export type ChecksheetEventType = 'VALUE_UPDATE' | 'STATUS_UPDATE';
export type ChecksheetStatus = 'COMPLETED' | 'APPROVED' | 'REJECTED';

export interface ChecksheetEntryInfoRef {
    id: string;
    templateId: string;
    configId: string;
    createdBy: string;
    createdOn: string;
    details: ChecksheetDetailRef[];
    data: CellDataInfo[];
    events: ChecksheetEntryEventRef[];
}

export interface ChecksheetEntryEventRef {
    updatedBy: string,
    id: string,
    value_update_size: number,
    status: ChecksheetStatus,
    entry_id: string,
    remarks: string,
    event_type: ChecksheetEventType,
    column_cordinates: string,
    updatedAt: string,
}

export interface CellDataInfo {
    id:string;
    coordinates: string;
    value: string[];
}

// Class for representing cell coordinates within a checksheet.
export class CellCoordinates {
    row: number;
    column: number;
    rowTag?: string;
    columnTag?: string;
    tag?: string;
    type: CellCoordinateType;
    end?: CellCoordinates;

    constructor({
                    row,
                    column,
                    tag,
                    type,
                    rowTag,
                    columnTag,
                    end,
                }: {
        row: number;
        column: number;
        tag?: string;
        type: CellCoordinateType;
        rowTag?: string;
        columnTag?: string;
        end?: CellCoordinates;
    }) {
        this.row = row;
        this.column = column;
        this.rowTag = rowTag;
        this.columnTag = columnTag;
        this.tag = tag;
        this.type = type;
        this.end = end;
    }

    get rowSpan(): number {
        if (this.type === 'single' || this.end === undefined) {
            return 1;
        }
        return this.end.row - this.row + 1;
    }

    get columnSpan(): number {
        if (this.type === 'single' || this.end === undefined) {
            return 1;
        }
        return this.end.column - this.column + 1;
    }

    get isCompound(): boolean {
        return this.type === 'compound';
    }

    static from(row: number, column: number) {
        let columnForProcessing = column;
        let columnTag = '';
        while (columnForProcessing > 0) {
            const modulo = (columnForProcessing - 1) % 26;
            columnTag = String.fromCharCode(65 + modulo) + columnTag;
            columnForProcessing = Math.floor((columnForProcessing - modulo) / 26);
        }

        const rowTag = row.toString();

        const tag = `${columnTag}${rowTag}`;

        return new CellCoordinates({
            row: row,
            column: column,
            tag: tag,
            rowTag: rowTag,
            columnTag: columnTag,
            type: 'single',
        });
    }

    static compound(coordinates: string[]): CellCoordinates {
        return new CellCoordinates({
            row: CellCoordinates.rowFromTag(coordinates[0]),
            column: CellCoordinates.columnFromTag(coordinates[0]),
            tag: coordinates.join(':'),
            rowTag: coordinates[0].replace(/\D/g, ''),
            columnTag: coordinates[0].replace(/\d/g, ''),
            type: coordinates.length > 1
                ? 'compound'
                : 'single',
            end: coordinates.length > 1 ? CellCoordinates.single(coordinates[1]) : undefined,
        });
    }

    static single(coordinates: string): CellCoordinates {
        return new CellCoordinates({
            row: CellCoordinates.rowFromTag(coordinates),
            column: CellCoordinates.columnFromTag(coordinates),
            rowTag: coordinates.replace(/\D/g, ''),
            columnTag: coordinates.replace(/\d/g, ''),
            type: 'single',
            tag: coordinates,
        });
    }

    static fromPlainObject(obj: any): CellCoordinates {
        return new CellCoordinates({
            row: obj.row,
            column: obj.column,
            rowTag: obj.rowTag,
            columnTag: obj.columnTag,
            tag: obj.tag,
            type: obj.type,
            end: obj.end ? CellCoordinates.fromPlainObject(obj.end) : undefined
        });
    }

    // Private method to extract the row index from a cell tag.
    private static rowFromTag(tag: string): number {
        return parseInt(tag.replace(/\D/g, ''));
    }

    // Private method to extract the column index from a cell tag.
    private static columnFromTag(tag: string): number {
        tag = tag.replace(/\d/g, '');
        let column = 0;
        for (let i = 0; i < tag.length; i++) {
            column = column * 26 + (tag.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
        }
        return column;
    }

    toPlainObject(): any {
        return {
            row: this.row,
            column: this.column,
            rowTag: this.rowTag,
            columnTag: this.columnTag,
            tag: this.tag,
            type: this.type,
            end: this.end ? this.end.toPlainObject() : undefined
        };
    }
}

export type CellCoordinateType = 'single' | 'compound';

// Class for representing a checksheet model.
export class ChecksheetTemplateRef {
    id: string;
    dataId?: string;
    title: string;
    description: string;
    dynamicRows: boolean;
    headers: ChecksheetRowRef[];
    rows: ChecksheetRowRef[];
    fixedDetails: ChecksheetFixedDetailRef[];
    entryDetails: ChecksheetDetailRef[];

    constructor({
                    id,
                    title,
                    description,
                    dynamicRows,
                    headers,
                    rows,
                    fixedDetails,
                    entryDetails,
                    dataId,
                }: {
        id: string;
        title: string;
        description: string;
        dynamicRows: boolean;
        headers: ChecksheetRowRef[];
        rows: ChecksheetRowRef[];
        fixedDetails: ChecksheetFixedDetailRef[];
        entryDetails: ChecksheetDetailRef[];
        dataId?: string;
    }) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.dynamicRows = dynamicRows;
        this.headers = headers;
        this.rows = rows;
        this.fixedDetails = fixedDetails;
        this.entryDetails = entryDetails;
        this.dataId = dataId;
        this.fillEmptyCells();
    }

    static fromMap(map: Record<string, any>): ChecksheetTemplateRef {
        const checksheet = new ChecksheetTemplateRef({
            id: map.id,
            title: map.title,
            description: map.description,
            dynamicRows: map.dynamic_rows === 1,
            headers: map.headers
                ? map.headers.map((row: any) => ChecksheetRowRef.fromMap(row))
                : [],
            rows: map.rows ? map.rows.map((row: any) => ChecksheetRowRef.fromMap(row)) : [],
            fixedDetails: map.fixed_details
                ? map.fixed_details.map((detail: any) => ChecksheetFixedDetailRef.fromMap(detail))
                : [],
            entryDetails: map.entry_details
                ? map.entry_details.map((detail: any) => ChecksheetDetailRef.fromMap(detail))
                : [],
            dataId: map.data_id,
        });

        checksheet.headers.sort((h1, h2) => {
            const o1 = h1.order
            const o2 = h2.order

            if (o1[o1.length - 1] < o2[0]) return -1
            else return 1
        })

        checksheet.rows.sort((r1, r2) => {
            const o1 = r1.order
            const o2 = r2.order

            if (o1[o1.length - 1] < o2[0]) return -1
            else return 1
        })


        return checksheet;
    }

    static fromPlainObject(obj: any): ChecksheetTemplateRef {
        return new ChecksheetTemplateRef({
            id: obj.id,
            title: obj.title,
            description: obj.description,
            dynamicRows: obj.dynamicRows,
            headers: obj.headers.map((header: any) => ChecksheetRowRef.fromPlainObject(header)),
            rows: obj.rows.map((row: any) => ChecksheetRowRef.fromPlainObject(row)),
            fixedDetails: obj.fixedDetails.map((detail: any) => ChecksheetFixedDetailRef.fromPlainObject(detail)),
            entryDetails: obj.entryDetails.map((detail: any) => ChecksheetDetailRef.fromPlainObject(detail)),
            dataId: obj.dataId,
        });
    }

    toPlainObject() {
        return {
            id: this.id,
            title: this.title,
            description: this.description,
            dynamicRows: this.dynamicRows,
            headers: this.headers.map(header => header.toPlainObject()),
            rows: this.rows.map(row => row.toPlainObject()),
            fixedDetails: this.fixedDetails.map(detail => detail.toPlainObject()),
            entryDetails: this.entryDetails.map(detail => detail.toPlainObject()),
            dataId: this.dataId,
        };
    }

    private fillEmptyCells() {
        let maxDataColumns = 0;
        let maxHeaderRow: ChecksheetRowRef | undefined;

        // Find the header with the maximum number of cells.
        this.headers.forEach((header) => {
            if (header.cells.length > maxDataColumns) {
                maxDataColumns = header.cells.length;
                maxHeaderRow = header;
            }
        });

        const dataColumnStart = maxHeaderRow?.cells[0]?.coordinates;
        const dataColumnEnd = maxHeaderRow?.cells[maxDataColumns - 1]?.coordinates;

        if (!dataColumnStart || !dataColumnEnd || !maxHeaderRow) {
            console.error("Headers missing or improperly formatted.");
            return;
        }

        this.rows.forEach((row) => {
            const expectedDataCellInRow = row.order.length * maxDataColumns;

            if (row.cells.length < expectedDataCellInRow) {
                row.order.forEach(orderRowNum => {
                    for (let j = 0; j < maxDataColumns; j++) {
                        const targetColumnNum = dataColumnStart.column + j;

                        const cellExists = row.cells.some(cell => cell.coordinates.row === orderRowNum + 1 && cell.coordinates.column === targetColumnNum);

                        if (!cellExists) {
                            const headerCell = maxHeaderRow?.cells[j];

                            if (headerCell) {
                                const newCell = new ChecksheetCellRef({
                                    rowId: row.id,
                                    id: uuid4(),
                                    description: '',
                                    inputCount: headerCell.inputCount,
                                    type: headerCell.type,
                                    coordinates: CellCoordinates.from(orderRowNum + 1, targetColumnNum),
                                    inputVariant: headerCell.inputVariant,
                                    inputSelections: headerCell.inputSelections,
                                    checksheet: this.id,
                                    isRowIdentifier: false,
                                    isMetadata: false,
                                });
                                row.cells.push(newCell);
                            }
                        }
                    }
                });
            }

            row.cells.sort((cellA, cellB) => {
                if (cellA.coordinates.row === cellB.coordinates.row) {
                    return cellA.coordinates.column - cellB.coordinates.column;
                }
                return cellA.coordinates.row - cellB.coordinates.row;
            });
        });
    }
}

// Class for representing fixed details within a checksheet.
export class ChecksheetFixedDetailRef {
    id: string;
    description?: string;
    data?: string;
    checksheet: string;

    constructor({
                    id,
                    description,
                    data,
                    checksheet,
                }: {
        id: string;
        description?: string;
        data?: string;
        checksheet: string;
    }) {
        this.id = id;
        this.description = description;
        this.data = data;
        this.checksheet = checksheet;
    }

    static fromMap(map: Record<string, any>): ChecksheetFixedDetailRef {
        return new ChecksheetFixedDetailRef({
            id: map.id,
            description: map.description,
            data: map.data,
            checksheet: map.checksheet,
        });
    }

    static fromPlainObject(obj: any): ChecksheetFixedDetailRef {
        return new ChecksheetFixedDetailRef({
            id: obj.id,
            description: obj.description,
            data: obj.data,
            checksheet: obj.checksheet
        });
    }

    toPlainObject() {
        return {
            id: this.id,
            description: this.description,
            data: this.data,
            checksheet: this.checksheet
        };
    }
}

// Class for representing entry details within a checksheet.
export class ChecksheetDetailRef extends ChecksheetFixedDetailRef {
    inputVariant?: ChecksheetInputVariant;
    inputSelections: CellSelectionRef[];
    inputValidations?: CellValidationRef[];

    constructor({
                    id,
                    description,
                    data,
                    checksheet,
                    inputVariant,
                    inputSelections,
                    inputValidations
                }: {
        id: string;
        description?: string;
        data?: string;
        checksheet: string;
        inputVariant?: ChecksheetInputVariant;
        inputSelections: CellSelectionRef[];
        inputValidations?: CellValidationRef[];
    }) {
        super({id, description, data, checksheet});
        this.inputVariant = inputVariant;
        this.inputSelections = inputSelections;
        this.inputValidations = inputValidations;
    }

    static fromMap(map: Record<string, any>): ChecksheetDetailRef {
        return new ChecksheetDetailRef({
            id: map.id,
            description: map.description,
            data: map.data === 'null' || map.data === '' || map.data === null ? null : map.data,
            checksheet: map.checksheet,
            inputVariant: map.input_variant,
            inputSelections: ChecksheetDetailRef.processSelections(map.input_selections),
        });
    }

    static processSelections(data: any[]): {
        order: number,
        selection: string,
        abbreviation: string
        isDefault: boolean
        colorSwatch: string
    }[] {
        if (!data) {
            return [];
        }
        return data.map((selection) => {
            return {
                order: selection.order,
                selection: selection.selection,
                abbreviation: selection.abbreviation,
                isDefault: selection.is_default,
                colorSwatch: selection.color_swatch
            }
        });
    }

    static fromPlainObject(obj: any): ChecksheetDetailRef {
        return new ChecksheetDetailRef({
            ...super.fromPlainObject(obj),
            inputVariant: obj.inputVariant,
            inputSelections: obj.inputSelections
        });
    }

    toPlainObject() {
        return {
            ...super.toPlainObject(),
            inputVariant: this.inputVariant,
            inputSelections: this.inputSelections
        };
    }
}

// Class for representing a row within a checksheet.
export class ChecksheetRowRef {
    id: string;
    checksheet: string;
    order: number[];
    isHeader: boolean;
    metadataCells: ChecksheetCellRef[];
    cells: ChecksheetCellRef[];

    constructor({
                    id,
                    order,
                    metadataCells,
                    cells,
                    checksheet,
                    isHeader,
                }: {
        id: string;
        order: number[];
        metadataCells: ChecksheetCellRef[];
        cells: ChecksheetCellRef[];
        checksheet: string;
        isHeader: boolean;
    }) {
        this.id = id;
        this.checksheet = checksheet;
        this.isHeader = isHeader;
        this.order = order;
        this.metadataCells = metadataCells;
        this.cells = cells;
    }

    static fromMap(map: Record<string, any>): ChecksheetRowRef {
        const row = new ChecksheetRowRef({
            id: map.id,
            checksheet: map.checksheet,
            isHeader: map.is_header === 1,
            order: ChecksheetRowRef.processOrder(map),
            metadataCells: map.metadata_cells
                ? map.metadata_cells.map((cell: any) => ChecksheetCellRef.fromMap(cell))
                : [],
            cells: map.cells
                ? map.cells.map((cell: any) => ChecksheetCellRef.fromMap(cell))
                : [],
        });

        row.cells.sort((cell1, cell2) => {
            const coord1 = cell1.coordinates;
            const coord2 = cell2.coordinates;
            if (coord1.column != coord2.column) return coord1.column - coord2.column;
            return coord1.row - coord2.row;
        });

        row.metadataCells.sort((cell1, cell2) => {
            const coord1 = cell1.coordinates;
            const coord2 = cell2.coordinates;
            if (coord1.column != coord2.column) return coord1.column - coord2.column;
            return coord1.row - coord2.row;
        });


        return row;
    }

    static fromPlainObject(obj: any): ChecksheetRowRef {
        return new ChecksheetRowRef({
            id: obj.id,
            checksheet: obj.checksheet,
            isHeader: obj.isHeader,
            order: obj.order,
            metadataCells: obj.metadataCells.map((cell: any) => ChecksheetCellRef.fromPlainObject(cell)),
            cells: obj.cells.map((cell: any) => ChecksheetCellRef.fromPlainObject(cell))
        });
    }

    private static processOrder(map: Record<string, any>): number[] {
        try {
            const order: number[] = Array.from(map.row_order);
            order.sort((o1, o2) => o1 - o2);

            return order;
        } catch (e) {
            const order: number[] = map.row_order.split(';').map((e: string) => parseInt(e));
            order.sort((o1, o2) => o1 - o2);
            return order;
        }
    }

    toPlainObject() {
        return {
            id: this.id,
            checksheet: this.checksheet,
            isHeader: this.isHeader,
            order: this.order,
            metadataCells: this.metadataCells.map(cell => cell.toPlainObject()),
            cells: this.cells.map(cell => cell.toPlainObject())
        };
    }
}

// Class for representing a cell within a checksheet.
export class ChecksheetCellRef extends ChecksheetDetailRef {
    rowId: string;
    type: CellType;
    coordinates: CellCoordinates;
    isMetadata: boolean;
    isRowIdentifier: boolean;
    cellData?: string[];
    inputCount?: number;

    constructor({
                    rowId,
                    id,
                    description,
                    data,
                    cellData,
                    inputCount,
                    type,
                    coordinates,
                    inputVariant,
                    inputSelections,
                    inputValidations,
                    checksheet,
                    isRowIdentifier,
                    isMetadata,
                }: {
        rowId: string;
        id: string;
        description?: string;
        data?: string;
        cellData?: string[];
        inputCount?: number;
        type: CellType;
        coordinates: CellCoordinates;
        inputVariant?: ChecksheetInputVariant;
        inputSelections: CellSelectionRef[];
        inputValidations?: CellValidationRef[];

        checksheet: string;
        isRowIdentifier: boolean;
        isMetadata: boolean;
    }) {
        super({id, description, data, checksheet, inputVariant, inputSelections});
        this.rowId = rowId;
        this.type = type;
        this.coordinates = coordinates;
        this.isMetadata = isMetadata;
        this.isRowIdentifier = isRowIdentifier;
        this.cellData = cellData;
        this.inputCount = inputCount || 1;
    }

    static fromMap(map: Record<string, any>): ChecksheetCellRef {
        return new ChecksheetCellRef({
            rowId: map.row_id,
            id: map.id,
            description: map.description,
            data: map.data,
            cellData: map.data ? map.data.split("|;|") : null,
            inputCount: map.input_count || 1,
            type: map.type,
            coordinates: CellCoordinates.compound(map.coordinates.split(':')),
            isMetadata: map.is_metadata === 1,
            isRowIdentifier: map.is_row_identifier !== null && map.is_row_identifier === 1,
            inputVariant: map.input_variant,
            inputSelections: ChecksheetDetailRef.processSelections(map.input_selections),
            inputValidations: map.input_validations
                ? map.input_validations.map((validation: any) => {
                    return {
                        type: validation.type,
                        constraintLevel: validation.constraint_level,
                        validation: validation.validation,
                        errorMessage: validation.error_message,
                    };
                })
                : [],
            checksheet: map.checksheet,
        });
    }

    static fromPlainObject(obj: any): ChecksheetCellRef {
        return new ChecksheetCellRef({
            ...super.fromPlainObject(obj),
            rowId: obj.rowId,
            type: obj.type,
            coordinates: CellCoordinates.fromPlainObject(obj.coordinates),
            isMetadata: obj.isMetadata,
            isRowIdentifier: obj.isRowIdentifier,
            cellData: obj.cellData,
            inputCount: obj.inputCount
        });
    }

    toPlainObject() {
        return {
            ...super.toPlainObject(),
            rowId: this.rowId,
            type: this.type,
            coordinates: this.coordinates.toPlainObject(),
            isMetadata: this.isMetadata,
            isRowIdentifier: this.isRowIdentifier,
            cellData: this.cellData,
            inputCount: this.inputCount
        };
    }
}

export interface CellValidationRef {
    type: string;
    constraintLevel: string;
    validation: string;
    errorMessage: string;
}

export interface CellSelectionRef {
    order: number;
    selection: string;
    abbreviation: string;
    isDefault: boolean;
    colorSwatch: string;
}
