import React, {createContext, ReactNode, useContext, useEffect, useMemo, useState} from "react"
import {useAppDispatch} from "@src/redux/hooks";
import BrokerService from "@common/services/broker.service";

interface IMqttContext {
    subscribe: (topic: string) => void
    unsubscribe: (topic: string) => void
    publish: (topic: string, message: string) => void
    isBrokerConnected: () => boolean
}

const MqttContext = createContext<IMqttContext | undefined>(undefined)

export const useBroker = (): IMqttContext => {
    const context = useContext(MqttContext)
    if (!context) {
        throw new Error('useBroker must be used within a BrokerProvider')
    }
    return context
}

export function BrokerProvider({children}: { children: ReactNode }) {
    const dispatch = useAppDispatch()
    const broker = useMemo(() => new BrokerService(dispatch), [dispatch])
    const [topics, setTopics] = useState<string[]>([])

    useEffect(() => {
        if (!broker.isConnected()) {
            console.log("Broker not connected. Attempting to connect((((((((");
            try {

            broker.connect()
            }catch (err){
                console.log("Connection error: in broker context ", err)
            }
        }
        console.log("is broker connected", broker.isConnected())

        return () => {
            if (broker.isConnected()) {
                console.log("Disconnecting broker)))))))))");
                broker.disconnect()
            }
        }
    }, [broker])
    const subscribe = (topic: string | string[]) => {
        const topicsToSubscribe = Array.isArray(topic) ? topic.filter(t => !topics.includes(t)) : [topic]

        broker.subscribe(topicsToSubscribe)
        setTopics([...topics, ...topicsToSubscribe])

    }

    const unsubscribe = (topic: string | string[]) => {
        const topicsToUnsubscribe = Array.isArray(topic) ? topic.filter(t => topics.includes(t)) : [topic]

        broker.unsubscribe(topicsToUnsubscribe)
        setTopics(topics.filter(t => !topicsToUnsubscribe.includes(t)))
    }

    const publish = (topic: string, message: string) => {
        broker.publish(topic, message)
    }
    const isBrokerConnected = () => {
        return broker.isConnected()
    }


    return (
        <MqttContext.Provider value={{subscribe, unsubscribe, publish, isBrokerConnected}}>
            {children}
        </MqttContext.Provider>
    )
}